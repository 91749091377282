<template>
    <div>

        <b-modal v-if="template" ref="deleting-modal" hide-header hide-footer modal-class="modal-primary" centered>
            <div class="p-1">
                <div>
                    <h4 class="line-height-condensed">Are you sure you want to Delete the Template <span
                            class="text-primary">{{ template.name }}</span></h4>
                </div>

                <div class="d-flex justify-content-end mt-3">
                    <b-button variant="outline-primary" @click="hideDeletingModal">
                        Cancel
                    </b-button>
                    <b-button variant="danger" @click="deleteTemplate" class="ml-1">
                        Delete
                    </b-button>
                </div>
            </div>
        </b-modal>
        <!-- /Deleting Modal -->

        <!-- Table Container Card -->
        <b-card no-body class="mb-0">
            <div class="m-2">
                <!-- Table Top -->
                <b-row>
                    <!-- Per Page -->
                    <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <label>Show</label>
                        <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions" :clearable="false"
                            class="per-page-selector d-inline-block mx-50" />
                        <label>entries</label>
                    </b-col>
                    <!-- Search -->
                    <b-col cols="12" md="6">
                        <div class="d-flex align-items-center justify-content-end">
                            <!-- <b-form-select v-model="typeQuery" :options="accountTypes" class="d-inline-block mr-1" /> -->



                            <b-button variant="primary" :to="{ name: 'TemplateCreate' }">
                                <span class="text-nowrap">Add Template</span>
                            </b-button>
                        </div>
                    </b-col>
                </b-row>
            </div>

            <b-table ref="templatesTable" class="position-relative text-nowrap" :items="templates" responsive
                :fields="tableColumns" show-empty empty-text="No matching records found" :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc">

                <template #cell(name)="data">
                    <b-link class="font-weight-bold d-block text-nowrap"
                        :to="{ name: 'TemplatesEdit', params: { templateId: data.item.id } }">
                        {{ data.item.name }}
                    </b-link>
                </template>

                

                <!-- Column: Actions -->
                <template #cell(actions)="data">
                    <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
                        <template #button-content>
                            <feather-icon icon="MoreVerticalIcon" size="13" class="align-middle text-body" />
                        </template>

                      

                        <b-dropdown-item @click="showDeletingModal(data.item)">
                            <feather-icon icon="XCircleIcon" />
                            <span class="align-middle ml-50">Delete</span>
                        </b-dropdown-item>

                        <b-dropdown-item :to="{ name: 'TemplatesEdit', params: { templateId: data.item.id } }">
                            <feather-icon icon="EditIcon" />
                            <span class="align-middle ml-50">Edit</span>
                        </b-dropdown-item>
                    </b-dropdown>
                </template>

            </b-table>

            <div class="mx-2 mb-2">
                <b-row>
                    <b-col cols="12" sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-start">
                        <span class="text-muted">
                            Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries
                        </span>
                    </b-col>
                    <!-- Pagination -->
                    <b-col cols="12" sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-end">
                        <b-pagination v-model="currentPage" :total-rows="totalTemplates" :per-page="perPage" first-number
                            last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>

                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </div>
        </b-card>
    </div>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormRadioGroup,
    BFormRadio,
    BFormInput,
    BFormTextarea,
    BButton,
    BTable,
    BPagination,
    BLink,
    BAvatar,
    BBadge,
    BDropdown,
    BDropdownItem,
    BFormSelect
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import ToastificationContent from '@core/components/toastification/ToastificationContent';
import Button from "@/views/components/button/Button.vue";
import Ripple from 'vue-ripple-directive'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required, } from '@validations'


export default {
    components: {
        ValidationProvider,
        ValidationObserver,
        Button,
        BCard,
        BRow,
        BCol,
        BLink,
        BAvatar,
        BBadge,
        BFormGroup,
        BFormRadioGroup,
        BFormRadio,
        BFormTextarea,
        BFormInput,
        BButton,
        BTable,
        BDropdown,
        BDropdownItem,
        BPagination,
        vSelect,
        BFormSelect
    },

    data() {
        return {
            currentPage: 1,
            perPage: 100,
            perPageOptions: [10, 25, 50, 100],
            nameQuery: '',
            emailQuery: '',
            typeQuery: '',
            tableColumns: [
                { key: 'name', label: 'Name', sortable: true },
                { key: 'subject', label: 'Subject', sortable: true },
                { key: 'emailFrom', label: 'From' },
                { key: 'actions', label: 'Actions' } // Add this line

            ],
            sortBy: 'name',
            sortDesc: false,
            templates: [],
            totalTemplates: 0,
            template: {},
            selectedReason: "",
            otherReason: null,
            required,
            accountTypes: ['Individual', 'Office']
        };
    },

    directives: {
        Ripple,
    },

    computed: {
        dataMeta() {
            return {
                from: this.perPage * (this.currentPage - 1) + (this.templates.length ? 1 : 0),
                to: this.perPage * (this.currentPage - 1) + this.templates.length,
                of: this.totalTemplates,
            }
        },

        queryParams() {
            return {
                sortBy: this.sortBy,
                sortOrder: this.sortDesc ? 1 : 0,
                skip: (this.currentPage - 1) * this.perPage,
                take: this.perPage,
            }
        },
    },

    watch: {
        queryParams: {
            handler() {
                this.fetchTemplates();
            },
            immediate: true
        }
    },

    methods: {
        async fetchTemplates() {


            const response = await this.$http.get('EmailTemplates', {

            });
            this.templates = response.data.results;
            this.totalTemplates = response.data.count;
        },

     

        showDeletingModal(template) {
            this.template = template;
            this.$refs["deleting-modal"].show();
        },

        hideDeletingModal() {
            this.$refs['deleting-modal'].hide();
            this.selectedReason = '';
            this.otherReason = null;
        },


        async deleteTemplate() {
            this.$refs["deleting-modal"].hide();

            const response = await this.$http.post(`EmailTemplates/Delete/${this.template.id}`, {

            });

            const successMessage = `${this.template.name} has been deleted`;

            this.$toast({
                component: ToastificationContent,
                props: {
                    title: successMessage,
                    icon: 'CheckCircleIcon',
                    variant: 'success',
                },
            });

            this.fetchTemplates();


            return true

        },

       
    }
};
</script>

<style lang="scss" scoped>
.per-page-selector {
    width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.other-option label {
    width: 100%;
}
</style>
